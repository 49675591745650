<template>
  <b-card-code>
    <h4>Result</h4>
    <b-row>
      <b-col md="4">
        <b-table-simple hover small caption-top responsive>
          <b-tbody>
            <b-tr>
              <b-th class="text-left">{{ $t('Student No') }}</b-th>
              <b-th class="text-left">: {{ cadetIdByID(single_result.student_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Name') }}</b-th>
              <b-th class="text-left">: {{ studentNameByID(single_result.student_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Class') }}</b-th>
              <b-th class="text-left">: {{ classByID(single_result.classes_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Version') }}</b-th>
              <b-th class="text-left">: {{ versionByID(single_result.version_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Shift') }}</b-th>
              <b-th class="text-left">: {{ shiftByID(single_result.shift_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Wing') }}</b-th>
              <b-th class="text-left">: {{ wingByID(single_result.wing_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('Student Group') }}</b-th>
              <b-th class="text-left">: {{ student_groupByID(single_result.student_group_id) }}</b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col md="3"></b-col>
      <b-col md="5">
        <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Interval(%)</b-th>
              <b-th class="text-center"  :colspan="1">Grade Name</b-th>
              <b-th class="text-center"  :colspan="1">Grade Point</b-th>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th class="text-center">{{ 0 +'-'+ 40 }}</b-th>
                <b-th class="text-center">{{ 'F' }}</b-th>
                <b-th class="text-center">{{ 0 }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center">{{ 50 +'-'+59}}</b-th>
                <b-th class="text-center">{{ 'B' }}</b-th>
                <b-th class="text-center">{{ 3 }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center">{{ 60 +'-'+69 }}</b-th>
                <b-th class="text-center">{{ 'A+' }}</b-th>
                <b-th class="text-center">{{ 3.5 }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center">{{ 70 +'-'+79 }}</b-th>
                <b-th class="text-center">{{ 'A' }}</b-th>
                <b-th class="text-center">{{ 4 }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center">{{ 80 +'-'+100 }}</b-th>
                <b-th class="text-center">{{ 'A+' }}</b-th>
                <b-th class="text-center">{{ 5 }}</b-th>
              </b-tr>
            </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <!-- form -->
    <b-table-simple hover small caption-top responsive>
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th class="text-center"  :colspan="1">SL</b-th>
          <b-th class="text-center"  :colspan="1">Subject</b-th>
          <b-th class="text-center"  :colspan="1">Subject Code</b-th>
          <b-th class="text-center"  :colspan="1">Full Mark</b-th>
          <template v-for="(md,key) in mark_types">
            <b-th class="text-center"  :key="key">{{ md }}</b-th>
            <b-th class="text-center"  :key="key+'p'">C. {{md}}</b-th>
          </template>
          <b-th class="text-center" >Total</b-th>
          <b-th class="text-center" >G. Total</b-th>
          <b-th class="text-center" >Grade</b-th>
          <b-th class="text-center" >GP</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(sub,key2) in jsonDecode(single_result.results)" :key="key2">
          <b-th class="text-center">{{ key2+1 }}</b-th>
          <b-th class="text-center">{{ sub.subject }} {{sub.is_optional?'(4th Subject)':''}}</b-th>
          <b-th class="text-center">{{ sub.code }}</b-th>
          <b-th class="text-center">{{ sub.total_mark }}</b-th>
          <template v-for="(md,key) in mark_types">
            <b-th :key="key" class="text-center" >{{ findMark(sub.marks,md)?findMark(sub.marks,md).obtain_mark:null }}</b-th>
            <b-th :key="key+'p'" class="text-center" >{{ findMark(sub.marks,md)?findMark(sub.marks,md).converted_mark:null }}</b-th>
          </template>
          <b-td class="text-center" >{{ single_result.obtained_mark }}</b-td>
          <b-td v-if="sub.has_part && sub.grand" rowspan="2" class="text-center" >
            {{sub.grand?sub.grand.grand_total:''}}
          </b-td>
          <b-td v-else-if="sub.has_part===0" rowspan="1" class="text-center" >
            {{sub.grand?sub.grand.grand_total:''}}
          </b-td>
          <b-td v-if="sub.has_part && sub.grand" rowspan="2" class="text-center" >
            {{sub.grand?sub.grand.grade:''}}
          </b-td>
          <b-td v-else-if="sub.has_part===0" rowspan="1" class="text-center" >
            {{sub.grand?sub.grand.grade:''}}
          </b-td>
          <b-td v-if="sub.has_part && sub.grand" rowspan="2" class="text-center" >
            {{sub.grand?sub.grand.gp:''}}
          </b-td>
          <b-td v-else-if="sub.has_part===0" rowspan="1" class="text-center" >
            {{sub.grand?sub.grand.gp:''}}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td :colspan="((mark_types.length*2) + 4)" class="text-center">
            {{ $t('Total Marks ,Grade & GPA') }}
          </b-td>
          <b-td class="text-center">{{ single_result.obtained_mark}}</b-td>
          <b-td class="text-center"></b-td>
          <b-td class="text-center">{{jsonDecode2(single_result.others)?jsonDecode2(single_result.others).grade_name:null}}</b-td>
          <b-td class="text-center">GPA {{jsonDecode2(single_result.others)?jsonDecode2(single_result.others).gp:null}}</b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTableSimple,BThead,BTr,BTd,BTfoot,BTh,BTbody,BRow,BCol,
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
export default {
  name:'SingleResult',
  props:{
    id:{
      required:true,
    }
  },
  components: {
    BCardCode,
    BThead,BTr,BTd,BTfoot,
    BTh,BTbody,BRow,BCol,
    BTableSimple,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      dir: false,
      mark_types:[],
      single_result:{},
      grades:[],
    }
  },
  methods:{
    findResult(){
      this.single_result = this.results.find(item=>item.id===parseInt(this.id));
    },
    /*
    * method for find mark
    * */
    findMark(mk,md){
      let mark = mk.find(item=>item.name===md);
      if(mark) return mark;
      else null;
    },
    findMarkTypes(){
      let e_type = this.exam_types.find(item=>item.id===this.single_result.exam_type_id);
      if(e_type){
        this.mark_types=this.jsonDecode(e_type.mark_types);
      }else this.mark_types=[];
    },
    findGrades(){
      let e_type = this.grade_types.find(item=>item.id===this.single_result.grade_type_id);
      if(e_type){
        this.grades=this.jsonDecode(e_type.grades);
      }else this.grades=[];
    },
    jsonDecode(data){
      try{
        return JSON.parse(data);
      }
      catch (e){
        return [];
      }
    },
    jsonDecode2(data){
      try{
        return JSON.parse(data);
      }
      catch (e){
        return null;
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['classes','classByID','versions','versionByID','shifts','shiftByID','wings','wingByID',
      'student_groups','student_groupByID','academic_years','academic_yearByID','students','studentIdByID',
      'studentNameByID','results','exam_types','mark_distributions','grade_types', 'cadetIdByID']),
  },
  created() {
    if(this.results.length <1) this.$store.dispatch('GET_ALL_RESULT');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.shifts.length <1) this.$store.dispatch('GET_ALL_SHIFT');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
    if(this.exam_types.length <1) this.$store.dispatch('GET_ALL_EXAM_TYPE');
    if(this.grade_types.length <1) this.$store.dispatch('GET_ALL_GRADE_TYPE');
    //if(this.mark_distributions.length <1) this.$store.dispatch('GET_ALL_MARK_DISTRIBUTION');
    this.findResult();
    this.findMarkTypes();
    this.findGrades();
  },
  watch: {
    id(){
      this.findResult();
      this.findMarkTypes();
      this.findGrades();
    },
    results(){
      this.findResult();
      this.findMarkTypes();
      this.findGrades();
    },
    grade_types(){
      this.findGrades();
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>